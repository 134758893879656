<template>
  <div>
    <a-radio-group
      @change="handleSelectView"
      :value="selectView"
      button-style="solid"
      size="large"
      class="mb-5"
    >
      <a-radio-button value="ringkasan">{{
        this.textRadio.ringkasan
      }}</a-radio-button>
      <a-radio-button value="rincian">{{textRadio.rincian}}</a-radio-button>
    </a-radio-group>
    <a-row :gutter="16" v-if="selectView === 'ringkasan'">
      <template v-if="showBio">
        <a-col :span="12">
          <a-form-item label="Nama">
            <a-input size="large" v-model="detilCatatan.nama" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="NIK">
            <a-input size="large" v-model="detilCatatan.nik" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="Golongan">
            <a-input size="large" v-model="detilCatatan.golongan" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="Status">
            <a-input size="large" v-model="detilCatatan.status" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="Unit">
            <a-input size="large" v-model="detilCatatan.unit" disabled />
          </a-form-item>
        </a-col>
      </template>
      <a-col :span="8">
        <a-form-item label="Hari Kerja Normal">
          <a-input size="large" v-model="detilCatatan.hari_kerja_normal" disabled />
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="Jumlah Hari Kerja">
          <a-input size="large" v-model="detilCatatan.jumlah_hari_kerja" disabled />
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="Tanggal Pembayaran">
          <a-date-picker v-if="detilCatatan.tanggal_pembayaran !== '-' && detilCatatan.tanggal_pembayaran" format="DD MMM YYYY" size="large" v-model="detilCatatan.tanggal_pembayaran_gaji" disabled />
          <a-input size="large" v-else v-model="detilCatatan.tanggal_pembayaran_gaji" disabled />
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="Total Penghasilan">
          <a-input class="text-right" addon-before="Rp" size="large" v-model="detilCatatan.total_penghasilan" disabled />
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="Total Pengurangan">
          <a-input class="text-right" addon-before="Rp" size="large" v-model="detilCatatan.total_pengurangan" disabled />
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="Total Gaji">
          <a-input class="text-right" addon-before="Rp" size="large" v-model="detilCatatan.total_gaji" disabled />
        </a-form-item>
      </a-col>
    </a-row>
    <a-row v-else-if="selectView === 'rincian'">
      <a-col v-if="checkParameter.length" class="mb-5" :span="24">
        <a-alert type="warning" show-icon message="Peringatan" :description="`${dataDetil.nama} belum memiliki ${checkParameter}. Harap berkoordinasi dengan bagian SDM/HR untuk penunjukan.`" banner />
      </a-col>
      <a-col :span="12"><div><b>Penghasilan</b></div></a-col>
      <a-col :span="12" class="pl-4"><div><b>Pengurangan</b></div></a-col>
      <a-col :span="24"><hr class="m-0 mt-2" /></a-col>
      <a-col :span ="12">
        <a-row :gutter="[16, 16]" class="my-3">
          <a-col :span ="8">Gaji Pokok</a-col>
          <a-col :span ="9"></a-col>
          <a-col :span ="7" class="d-flex">
            <div>Rp</div>
            <div class="text-right w-100">{{thousandPrice(dataDetil.gaji_pokok)}}</div>
          </a-col>
        </a-row>
        <a-row :gutter="[16, 16]" class="my-3" v-for="(penghasilan, i) in dataDetil.penghasilan" :key="i">
          <a-col :span ="8">{{underscoreParser(penghasilan.nama)}}</a-col>
          <a-col :span ="9">
            <template v-if="penghasilan.nama !== 'tunjangan_operasional' && penghasilan.pengali">
              {{
                penghasilan.parameter === 'gaji_pokok' ? `${penghasilan.pengali} % X Rp ${thousandPrice(dataDetil[penghasilan.parameter])}` : `${dataDetil[penghasilan.parameter]} X Rp ${thousandPrice(penghasilan.pengali)}`
              }}
            </template>
          </a-col>
          <a-col :span ="7" class="d-flex">
            <div>Rp</div>
            <div class="text-right w-100">{{thousandPrice(penghasilan.hasil)}}</div>
          </a-col>
          <!-- <a-col>{{dataDetil}}</a-col> -->
        </a-row>
      </a-col>
      <!--pengurangan -->
      <a-col class="pl-4" :span ="12">
        <template v-if="dataDetil.pengurangan && dataDetil.pengurangan.length">
          <a-row :gutter="[16, 16]" class="my-3" v-for="(pengurangan, i) in dataDetil.pengurangan" :key="i">
          </a-row>
        </template>
        <div class="my-4" v-else>
          <EmptyState heading="Tidak Ada Pengurangan" />
        </div>
      </a-col>
      <!--pengurangan -->
      <a-col :span="24"><hr class="m-0 mt-2" /></a-col>
      <a-col :span="12">
        <a-row :gutter="[16, 16]" class="my-3">
          <a-col :span ="17" class="text-center"><b>Total Penghasilan</b></a-col>
          <a-col :span ="7" class="d-flex">
            <div>Rp</div>
            <div class="text-right w-100">{{thousandPrice(dataDetil.total_penghasilan)}}</div>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="12">
        <a-row :gutter="[16, 16]" class="my-3">
          <a-col :span ="17" class="pl-4 text-center"><b>Total Pengurangan</b></a-col>
          <a-col :span ="7" class="d-flex">
            <div>Rp</div>
            <div class="text-right w-100">{{thousandPrice(dataDetil.total_pengurangan)}}</div>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="24"><hr class="m-0" /></a-col>
      <a-col :span="12" class="my-3">
        <a-row :gutter="[16, 16]">
          <a-col :span ="17" class="text-center"><b>Total Gaji</b></a-col>
          <a-col :span ="7" class="d-flex">
            <div>Rp</div>
            <div class="text-right w-100">{{thousandPrice(dataDetil.total_gaji)}}</div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import thousandPrice from '@/helpers/thousandPrice'
const EmptyState = () => import('@/components/app/EmptyState')
export default {
  props: {
    dataDetil: Object,
    showBio: Boolean,
  },
  watch: {
    'dataDetil.key'(newVal) {
      // console.log(newVal, this.dataDetil, 'oyesssl')
      if (newVal) {
        // this.detilAbsensi = { ...this.data }
        // this.detilAbsensi = this.checkAbsen(this.data)
        this.detilCatatanGenerate()
        // this.detilCatatan = {
        //   nik: '',
        //   nama: '',
        //   tanggal_pembayaran_gaji: '',
        //   status: '',
        //   total_gaji: 0,
        //   total_pengurangan: 0,
        //   total_penghasilan: 0,
        // }
      }
    },
  },
  methods: {
    detilCatatanGenerate() {
      for (const key in this.detilCatatan) {
        if (!this.dataDetil[key] || this.dataDetil[key] === undefined) {
          // console.log('masukk', key)
          if (key.split('_')[0] === 'total') {
            this.detilCatatan[key] = thousandPrice(0)
          } else {
            this.detilCatatan[key] = '-'
          }
        } else {
          if (key.split('_')[0] === 'total') this.detilCatatan[key] = thousandPrice(this.dataDetil[key])
          else if (key === 'status') this.detilCatatan[key] = this.dataDetil[key] === 'unpaid' ? 'BELUM TERBAYAR' : 'TERBAYAR'
          else this.detilCatatan[key] = this.dataDetil[key]
        }
        console.log(this.detilCatatan)
      }
    },
    underscoreParser(string) {
      const data = string.split('_')
      let newString = ''
      data.forEach(el => {
        if (el === 'tunjangan') newString += 'Tunj. '
        else newString += (el[0].toUpperCase() + el.split('').slice(1).join(''))
      })
      return newString
    },
    handleSelectView(e) {
      // console.log(this.selectView, e)
      this.selectView = e.target.value
    },
  },
  computed: {
    checkParameter() {
      const parameter = []
      if (!this.dataDetil.unit) parameter.push(' unit')
      if (!this.dataDetil.golongan) parameter.push(' golongan')
      if (!this.dataDetil.jabatan?.length) {
        if (parameter.length > 0) parameter.push(' dan jabatan')
        else parameter.push('jabatan')
      }
      return parameter
    },
  },
  data() {
    return {
      thousandPrice,
      selectView: 'ringkasan',
      textRadio: {
        ringkasan: 'Ringkasan',
        rincian: 'Rincian',
      },
      detilCatatan: {
        nik: '',
        nama: '',
        tanggal_pembayaran_gaji: '',
        status: '',
        unit: '',
        total_gaji: '',
        total_penghasilan: '',
        total_pengurangan: '',
        hari_kerja_normal: 0,
        jumlah_hari_kerja: 0,
        golongan: '',
      },
    }
  },
  components: { EmptyState },
  created() {
    if ('key' in this.dataDetil) this.detilCatatanGenerate()
  },
}
</script>
